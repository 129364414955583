import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Stats.css';

function StatsSection() {
  const [stats, setStats] = useState({
    titans: 0,
    totalDeploys: 0,
    totalVolume: 0,
  });
  const [loading, setLoading] = useState(true);

  // Fetch data from the API
  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get('https://titandeployer.xyz/stats');
        const { totalDeployers, totalDeploys, totalVolumeUSD } = response.data;
        setStats({
          titans: totalDeployers,
          totalDeploys: totalDeploys,
          totalVolume: totalVolumeUSD,
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching stats:', error);
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <section className="stats-section">
      <div className="stat-item">
        <h3>Titans</h3>
        <p className="stat-value">{stats.titans}</p>
      </div>
      <div className="stat-item">
        <h3>Total Deploys</h3>
        <p className="stat-value">{stats.totalDeploys}</p>
      </div>
      <div className="stat-item">
        <h3>Total Volume</h3>
        <p className="stat-value">${stats.totalVolume}</p> {/* Convert to M for display */}
      </div>
    </section>
  );
}

export default StatsSection;
