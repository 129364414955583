import React, { useState, useEffect, useMemo } from 'react';
import { ethers } from 'ethers';
import '../styles/Header.css';

function Header() {
  const [gasPrice, setGasPrice] = useState(localStorage.getItem('gasPrice') || null); // Get the cached gas price from localStorage

  // Memoize the provider so it doesn't get recreated on every render
  const provider = useMemo(() => {
    console.log('Creating WebSocketProvider');
    return new ethers.providers.WebSocketProvider('wss://homienode.online');
  }, []);

  useEffect(() => {
    const fetchGasPrice = async () => {
      try {
        console.log('Fetching initial gas price');
        // Fetch gas price initially
        const gasPriceData = await provider.getGasPrice();
        const formattedGasPrice = parseFloat(ethers.utils.formatUnits(gasPriceData, 'gwei')).toFixed(2);
        
        // Update state and localStorage with the new gas price
        setGasPrice(formattedGasPrice);
        localStorage.setItem('gasPrice', formattedGasPrice);

        // Subscribe to new blocks
        provider.on('block', async (blockNumber) => {
          console.log('New block received:', blockNumber);
          const newGasPriceData = await provider.getGasPrice();
          const formattedNewGasPrice = parseFloat(ethers.utils.formatUnits(newGasPriceData, 'gwei')).toFixed(2);
          
          setGasPrice(formattedNewGasPrice);
          localStorage.setItem('gasPrice', formattedNewGasPrice); // Update cache with new value
        });
      } catch (error) {
        console.error('Error fetching gas price:', error);
        setGasPrice('Error fetching gas');
      }
    };

    fetchGasPrice();

    // Cleanup on component unmount
    return () => {
      console.log('Cleaning up WebSocket listeners');
      provider.removeAllListeners('block');
    };
  }, [provider]); // Provider is now stable because of useMemo

  return (
    <header className="header">
      <div className="logo">
        <img src="logo.png" alt="Titan Deployer Logo" />
        <h1>Titan Deployer</h1>
      </div>
      <div className="gas-data">
        {gasPrice ? (
          <p>Gas: {gasPrice} Gwei</p>
        ) : (
          <p>Loading Gas Price...</p>
        )}
      </div>
      <div className="hamburger-menu">
        <button>
          <i className="fas fa-bars"></i>
        </button>
      </div>
    </header>
  );
}

export default Header;
