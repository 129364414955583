import React, { useState, useEffect, useRef } from 'react';
import '../styles/TokenList.css';
import axios from 'axios';

function TokenList() {
  const [tokenData, setTokenData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const tokensPerPage = 10;

  // Cache tokenData to avoid loading spinner
  const cachedTokenData = useRef([]);

  // Fetch token data from the API
  const fetchTokenData = async () => {
    try {
      const response = await axios.get('https://titandeployer.xyz/stats');
      if (response.data && response.data.tokens) {
        cachedTokenData.current = response.data.tokens; // Cache the token data
        localStorage.setItem('tokenData', JSON.stringify(response.data.tokens)); // Save to localStorage
        setTokenData(response.data.tokens);
      } else {
        throw new Error('Invalid response structure');
      }
    } catch (error) {
      console.error('Error fetching token data:', error);
      setError('Failed to load token data. Please try again later.');
    }
  };

  // useEffect for the initial fetch and setting up the interval for refreshing every 15 seconds
  useEffect(() => {
    // Try to load token data from localStorage first
    const storedData = localStorage.getItem('tokenData');
    if (storedData) {
      setTokenData(JSON.parse(storedData)); // Load from cache if available
    }

    fetchTokenData(); // Fetch data initially

    const intervalId = setInterval(() => {
      fetchTokenData(); // Fetch data every 15 seconds in the background
    }, 15000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Calculate total pages
  const totalPages = Math.ceil(tokenData.length / tokensPerPage);

  // Get the current set of tokens based on the current page
  const indexOfLastToken = currentPage * tokensPerPage;
  const indexOfFirstToken = indexOfLastToken - tokensPerPage;
  const currentTokens = tokenData.slice(indexOfFirstToken, indexOfLastToken);

  // Handlers for pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="token-list-section">
      <h2 className="tokens-heading">Tokens Deployed</h2>
      <div className="token-list-header">
        <div>Name</div>
        <div>Price</div>
        <div>Market Cap</div>
        <div>Volume</div>
      </div>

      <div className="token-list">
        {currentTokens.map((token, index) => (
          <div className={`token-item ${index === 0 ? 'first-token' : ''}`} key={index}>
            <div className="token-name">
              <img src={`./logos/${token.logo}`} alt={`${token.name} Logo`} className="token-logo" />
              {token.symbol}
            </div>
            <div className="token-price">{token.price}</div>
            <div className="token-marketcap">{token.marketCap}</div>
            <div className="token-volume">{token.volume}</div>
          </div>
        ))}
      </div>

      <div className="pagination-controls">
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
}

export default TokenList;
