import React from 'react';
import Header from './components/Header';
import StatsSection from './components/Stats';
import TokenList from './components/TokenList';
import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <div className="common-container">
        <StatsSection />
        <TokenList />
      </div>
    </div>
  );
}

export default App;
